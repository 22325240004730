import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import CategoryTaboola from '../subcomponents/CategoryTaboola';
import HomeTaboola from "../subcomponents/HomeTaboola";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { CategoryFetchData } from "../../redux/actions/categoryAction";
import { homePrimaryFetchData } from "../../redux/actions/homePrimaryAction";
import { Helmet } from "react-helmet";
import InjectScript from "../CustomScript";
import "../../styles/custom.css";
import HelperScript from "../../utils/helper";
import ReactGA from "react-ga4";
import CommonContent from "../CommonContent";
import TaboolaComponent from "@taboola/taboola-react-web";
// import YouTube from 'react-youtube';
import Dailymotion from "react-dailymotion";
import Carousel from "react-multi-carousel";
import { useRef } from "react";
import { sportScoreFetchData } from "../../redux/actions/sportScoreAction";
import { EntertainmentFetchData } from "../../redux/actions/entertainmentAction";

const totalNum_recored = 50; // 24

const GaneshChaturthiPage = (props) => {
  const urlParam = new URLSearchParams(window.location.search);
  const myParamPage = parseInt(urlParam.get("page"));
  const [iframeSrc, setiframeSrc] = useState("");
  const [zoomimg, zoomImg] = useState("");

  const [activePage, setActivePage] = React.useState(
    myParamPage ? parseInt(myParamPage) : 1,
  );
  const sports = useSelector((state) => state?.SportScoreReducer?.data);
  const fixtures = useSelector(
    (state) => state?.SportScoreReducer?.data?.fixtures,
  );
  const gallery = useSelector(
    (state) => state?.SportScoreReducer?.data?.gallery,
  );
  const video = useSelector((state) => state?.SportScoreReducer?.data?.video);
  const [activeTab, setActiveTab] = useState(1);
  const [sportData, setSportData] = useState();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1200, min: 991 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 515 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 515, min: 0 },
      items: 1,
    },
  };
  let carousel = useRef(null);
  const [vidId, setvidId] = useState("");
  const [vidTitle, setvidTitle] = useState("");
  const [vidTime, setvidTime] = useState("");
  // console.log('activePageactivePageactivePage', activePage);
  const [pageArray, setPageArray] = React.useState([]);
  const [Topten, setTopten] = React.useState([]);
  const homePrimary = useSelector((state) => state?.HomePrimaryReducer.data);
  const homeEntertainment = useSelector(
    (state) => state?.EntertainmentReducer.data,
  );
  const innerData = useSelector((state) => state?.CategoryReducer?.data?.posts);
  // const innerDataTopTen = useSelector((state) => state?.CategoryReducer?.data?.topten)
  const pageCount = useSelector((state) => state?.CategoryReducer?.data?.count);
  const pageName = useSelector((state) => state?.CategoryReducer?.data?.name);
  // const pagemessage = useSelector((state) => state?.CategoryReducer?.data?.message)
  const loading = useSelector((state) => state?.CategoryReducer?.loading);
  const { 21: assetbaseUrl } = useSelector(
    (state) => state?.SettingReducer?.data,
  );

  // const { 43: Categoryheader } = useSelector((state) => state?.widgetReducer?.data);
  // const { 47: CategoryFooter } = useSelector((state) => state?.widgetReducer?.data);

  // const { 57: firstright } = useSelector((state) => state?.widgetReducer?.data);
  // const { 58: secondright } = useSelector((state) => state?.widgetReducer?.data);
  // const { 59: thirdright } = useSelector((state) => state?.widgetReducer?.data);
  // const { 100: fourthright } = useSelector((state) => state?.widgetReducer?.data);

  // const { 51: threepost } = useSelector((state) => state?.widgetReducer?.data);
  // const { 52: sixpost } = useSelector((state) => state?.widgetReducer?.data);
  // const { 53: ninepost } = useSelector((state) => state?.widgetReducer?.data);
  // const { 54: twelvepost } = useSelector((state) => state?.widgetReducer?.data);

  const { url, country, state, city } = useParams();
  let dispatch = useDispatch();
  const config = {
    ...(country && { country: country }),
    ...(state && { state: state }),
    ...(city && { city: city }),
    page: activePage,
    limit: totalNum_recored,
  };

  useEffect(() => {
    // dispatch(homePrimaryFetchData());
    if (pageCount) {
      const checkPageSize = Math.ceil(pageCount / totalNum_recored);
      const array = Array.from(Array(checkPageSize).keys());
      setPageArray(array);
    }
    ReactGA.event({
      category: "Category Page",
      action: "Category",
      label: innerData?.name,
    });
    if (innerData && innerData.length) {
      const vidoytid = innerData[0]?.video || "neC9XY9aej4";
      setiframeSrc(
        "https://www.youtube.com/embed/" + vidoytid + "?autoplay=true&mute=1",
      );
    }
  }, [innerData, pageCount, url]);

  let navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = parseInt(urlParams.get("page")) || 1;
    if (myParam !== parseInt(activePage)) {
      if (parseInt(activePage) === 1) {
        navigate(window.location.pathname);
      } else {
        navigate(window.location.pathname + "?page=" + activePage);
      }
    }
    window.scrollTo(0, 0);
    dispatch(
      CategoryFetchData(
        "ganesh",
        config,
        url + `?page=${activePage}&limit=${totalNum_recored}`,
      ),
    );
    ReactGA.event({
      category: "Category Page",
      action: "Category",
      label: innerData?.name,
    });
  }, [activePage]);

  const VideoModalOpen = (VidDataId, VidDataTitle, VidDataTime) => {
    setvidId(VidDataId);
    setvidTitle(VidDataTitle);
    setvidTime(VidDataTime);
    document.getElementById("videoPoPup").classList.add("active-modal");
    document.body.style.overflow = "hidden";
  };

  const VideoModalClose = () => {
    setvidId("");
    document.body.style.overflow = "auto";
    document.getElementById("videoPoPup").classList.remove("active-modal");
  };
  const optsModal = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const taboolacontent =
    "" +
    "" +
    // '<div id="taboola-right-rail-thumbnails"></div>' +
    "" +
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    "_taboola.push({" +
    "mode: 'thumbnails-rr'," +
    "container: 'taboola-right-rail-thumbnails'," +
    "placement: 'Right Rail Thumbnails'," +
    "target_type: 'mix'" +
    "});" +
    "</script>" +
    "" +
    "";

  const Taboola1x1thumbs =
    "" +
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    "_taboola.push({" +
    "mode: 'thumbnails-c'," +
    "container: 'taboola-categorypage-1x1-thumbs'," +
    "placement: 'Categorypage-1x1-thumbs'," +
    "target_type: 'mix'" +
    "});" +
    "</script>" +
    "" +
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    " _taboola.push({flush: true});" +
    "</script>" +
    "";

  const businessWidget1 =
    "" +
    '<div style="height:433px; background-color: #FFFFFF; overflow:hidden; box-sizing: border-box; border: 1px solid #56667F; border-radius: 4px; text-align: right; line-height:14px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; box-shadow: inset 0 -20px 0 0 #56667F; padding: 0px; margin: 0px; width: 100%;"><div style="height:413px; padding:0px; margin:0px; width: 100%;"><iframe loading="lazy" src="https://widget.coinlib.io/widget?type=full_v2&theme=light&cnt=6&pref_coin_id=1505&graph=yes" width="100%" height="409px" scrolling="auto" marginwidth="0" marginheight="0" frameborder="0" border="0" style="border:0;margin:0;padding:0;"></iframe></div><div style="color: #FFFFFF; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;"><a href="https://coinlib.io" target="_blank" style="font-weight: 500; color: #FFFFFF; text-decoration:none; font-size:11px">Cryptocurrency Prices</a>&nbsp;by Coinlib</div></div>' +
    "";

  const businessWidget2 =
    "" +
    '<div class="tradingview-widget-container"><div class="tradingview-widget-container__widget"></div><div class="tradingview-widget-copyright"><a href="https://in.tradingview.com/markets/indices/" rel="noopener" target="_blank"><span class="blue-text">Indices</span></a> <span class="blue-text">and</span> <a href="https://in.tradingview.com/markets/currencies/" rel="noopener" target="_blank"><span class="blue-text">Forex</span></a> by TradingView</div>' +
    '<script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js" async> {"colorTheme": "light","dateRange": "12M","showChart": false,"locale": "in","largeChartUrl": "","isTransparent": false,"showSymbolLogo": true,"showFloatingTooltip": false,"width": "400","height": "465","tabs": [ { "title": "Indices", "symbols": [ { "s": "FOREXCOM:SPXUSD","d": "S&P 500" }, {"s": "FOREXCOM:NSXUSD", "d": "US 100" },' +
    '{ "s": "FOREXCOM:DJI", "d": "Dow 30" }, { "s": "INDEX:NKY", "d": "Nikkei 225"  }, { "s": "INDEX:DEU40", "d": "DAX Index"},' +
    '{ "s": "FOREXCOM:UKXGBP", "d": "UK 100" },{"s": "BSE:SENSEX" }, { "s": "CAPITALCOM:NIFTY50" } ], "originalTitle": "Indices" },' +
    '{"title": "Forex","symbols": [ { "s": "FX:EURUSD", "d": "EUR/USD" }, { "s": "FX:GBPUSD", "d": "GBP/USD" }, {"s": "FX:USDJPY", "d": "USD/JPY" },' +
    '{"s": "FX:USDCHF","d": "USD/CHF"},{"s": "FX:AUDUSD","d": "AUD/USD"},{"s": "FX:USDCAD","d": "USD/CAD"}], "originalTitle": "Forex"}]}' +
    "</script> </div>" +
    "" +
    "";

  const businessWidget3 =
    "" +
    '<div class="tradingview-widget-container"><div class="tradingview-widget-container__widget"></div>' +
    '<div class="tradingview-widget-copyright"><a href="https://in.tradingview.com/markets/stocks-india/" rel="noopener" target="_blank"><span class="blue-text">Stock Market Today</span></a> by TradingView</div>' +
    '<script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js" async>' +
    '{ "colorTheme": "light","dateRange": "12M","exchange": "BSE","showChart": false,"locale": "in","largeChartUrl": "",' +
    ' "isTransparent": false,"showSymbolLogo": true,"showFloatingTooltip": false, "width": "400","height": "407"} </script> </div>' +
    "" +
    "";

  const Titlecapitalize = (str, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
      match.toUpperCase(),
    );
  // const category201 = " <div id='div-gpt-ad-1646388561181-0' style='min-width: 300px; min-height: 250px;'>  <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388561181-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388561181-0'); }); </script> </div>"
  const category201 =
    "" +
    " <div id='div-gpt-ad-1646388561181-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388561181-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388561181-0'); }); </script> </div>" +
    // '<div id="gpt-passback">'+
    // '<script>'+
    // 'window.googletag = window.googletag || {cmd: []};'+
    // 'googletag.cmd.push(function() {'+
    // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x250', [300, 250], 'gpt-passback').addService(googletag.pubads());"+
    // 'googletag.enableServices();'+
    // "googletag.display('gpt-passback');"+
    // '});'+
    // '</script>'+
    // '</div>'+
    "";
  const category57 =
    " <div id='div-gpt-ad-1646037902231-0' style='min-width: 300px; min-height: 250px;'>  <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646037902231-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.enableServices(); }); </script><script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646037902231-0'); }); </script> </div>";
  const category202 =
    "  <div id='div-gpt-ad-1646388591019-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_CP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646388591019-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388591019-0'); }); </script> </div>";
  const category58 =
    " <div id='div-gpt-ad-1646037931655-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_CP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646037931655-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646037931655-0'); }); </script> </div>";

  // const afterPrimary  = "" +
  // '<div id = "v-sandesh-v0"></div>'+
  // ''+
  // '<script>'+
  // '(function(v,d,o,ai){'+
  // "ai=d.createElement('script');"+
  // 'ai.defer=true;'+
  // 'ai.async=true;'+
  // 'ai.src=v.location.protocol+o;'+
  // 'd.head.appendChild(ai);'+
  // '})'+
  // "(window, document, '//a.vdo.ai/core/v-sandesh-v0/vdo.ai.js');"+
  // "</script>"+
  // ''
  // const afterPrimary  = "" +
  // '<script defer id="vidcrunchJS632259138" src="https://cdn.vidcrunch.com/integrations/5f4fa0fc4f13d250c8dd1b3a/Sandesh.com_Both_ICF_Responsive_Playlist_190922.js">'+
  // '</script>'+
  // ''
  const afterPrimary =
    "" +
    "<div id='div-gpt-ad-1677043128794-0' style='min-width: 728px; min-height: 90px; text-align:center;'>" +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/SDPL_CP_AfterArticle1_728x90', [728, 90], 'div-gpt-ad-1677043128794-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "<script>" +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1677043128794-0'); });" +
    "</script>" +
    "</div>" +
    "";

  const afterPrimaryad2 =
    "" +
    "<div id='div-gpt-ad-1677046186534-0' style='min-width: 728px; min-height: 90px; text-align:center;'>" +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/SDPL_CP_AfterArticle2_728x90', [728, 90], 'div-gpt-ad-1677046186534-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "<script>" +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1677046186534-0'); });" +
    "</script>" +
    "</div>" +
    "";
  const cricketwidget =
    "" +
    '<iframe loading="lazy" src="https://bwidget.crictimes.org/" style="width:100%;min-height: 250px;" frameborder="0" scrolling="yes"></iframe>' +
    "";
  const IBVcategoryMobile =
    "" +
    "<div id='div-gpt-ad-1672904471597-0' style='min-width: 300px; min-height: 250px;'>" +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/Mweb_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1672904471597-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "<script>" +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1672904471597-0'); });" +
    "</script>" +
    "</div>" +
    "";
  const IBVcategoryWeb =
    "" +
    "<div id='div-gpt-ad-1669617435638-0' style='min-width: 300px; min-height: 250px;'>" +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/SDPL_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1669617435638-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "<script>" +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669617435638-0'); });" +
    "</script>" +
    "</div>" +
    "";

  const UniBotsplayer =
    "" +
    // '<script async src="https://cdn.unibotscdn.com/ubplayer/player.js"></script>' +
    // '<style>div#unibots-video { text-align: center; margin: auto; }</style>' +
    // '<div class="text-center">' +
    // '<div id="div-ub-sandesh_category" class="text-center">' +
    // '<script>' +
    // 'window.unibots = window.unibots || { cmd: [] };' +
    // 'unibots.cmd.push(()=>{ unibotsPlayer("sandesh_category"); });' +
    // '</script>' +
    // '</div>' +
    // '</div>' +
    "";

  const catevidcrunch =
    "" +
    '<script async id="AV65a444ede4eff44cc4066a64" type="text/javascript" src=" https://tg1.vidcrunch.com/api/adserver/spt?AV_TAGID=65a444ede4eff44cc4066a64&AV_PUBLISHERID=5b8cff1a28a06149a014c4b0"></script>' +
    "";

  useEffect(() => {
    document.body.style.overflow = !zoomimg ? "auto" : "hidden";
  }, [zoomimg]);

  return (
    <>
      {props.type === "location" && (
        <Helmet>
          <title>Location | Sandesh</title>
        </Helmet>
      )}
      {pageName?.length && (
        <Helmet>
          <title>{Titlecapitalize(pageName)} | Sandesh</title>
        </Helmet>
      )}
      {/* {Categoryheader?.[0]?.script && */}
      <div className="ad-section">
        {/* <HelperScript argSmall={190} argLarge={43} /> */}
        {/* <InjectScript script={Categoryheader?.[0]?.script} /> */}
      </div>
      {/* } */}
      <section
        className="gujarat isCategoryPage GaneshChaturthiPage"
        style={{ opacity: loading ? "0.5" : 1 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-8 col-xl-9 gujarat-responsive-space-sm">
              {innerData?.length > 0 ? (
                <div
                  className={`shadow p-3 mb-3 bg-white rounded  ${window.location.pathname === "/celeb-duniya" ? `d-none` : ""}`}
                >
                  {/* {innerData?.length > 0 ? <div className="shadow p-3 mb-3 bg-white rounded maincategory"> */}
                  <div className="row">
                    <div className="col-xl-12 megapreView col-lg-12">
                      {innerData?.length > 0 &&
                        innerData?.map((els, idx) => (
                          <>
                            {idx === 0 && (
                              <div className="img-zoomer">
                                <img
                                  loading="lazy"
                                  key={Math.random()}
                                  src={`${assetbaseUrl?.value}${els?.media}`}
                                  onClick={() =>
                                    zoomImg(
                                      `${assetbaseUrl?.value}${els?.media}`,
                                    )
                                  }
                                  className="gallery-comp-top-img new_gallery_img"
                                  alt=""
                                />
                                <img
                                  loading="lazy"
                                  key={Math.random()}
                                  src={`${assetbaseUrl?.value}${els?.media}`}
                                  onClick={() =>
                                    zoomImg(
                                      `${assetbaseUrl?.value}${els?.media}`,
                                    )
                                  }
                                  className="gallery-comp-top-img new_gallery_img_sub"
                                  alt=""
                                />
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                  <div className="row substory">
                    {innerData?.length > 0 &&
                      innerData?.map((els, idx) => (
                        <>
                          {idx > 0 && idx < 4 && (
                            <div className="col-lg-6 col-xl-4">
                              <div className="img-zoomer">
                                <img
                                  loading="lazy"
                                  key={Math.random()}
                                  src={`${assetbaseUrl?.value}${els?.media}`}
                                  onClick={() =>
                                    zoomImg(
                                      `${assetbaseUrl?.value}${els?.media}`,
                                    )
                                  }
                                  className="gallery-comp-sub-img img-responsive new_gallery_img"
                                  alt=""
                                />
                                <img
                                  loading="lazy"
                                  key={Math.random()}
                                  src={`${assetbaseUrl?.value}${els?.media}`}
                                  onClick={() =>
                                    zoomImg(
                                      `${assetbaseUrl?.value}${els?.media}`,
                                    )
                                  }
                                  className="gallery-comp-sub-img img-responsive new_gallery_img_sub"
                                  alt=""
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                  <div className="row substory">
                    {innerData?.length > 0 &&
                      innerData?.map((els, idx) => (
                        <>
                          {idx > 3 && idx < 7 && (
                            <div className="col-lg-6 col-xl-4">
                              <div className="img-zoomer">
                                <img
                                  loading="lazy"
                                  key={Math.random()}
                                  src={`${assetbaseUrl?.value}${els?.media}`}
                                  onClick={() =>
                                    zoomImg(
                                      `${assetbaseUrl?.value}${els?.media}`,
                                    )
                                  }
                                  className="gallery-comp-sub-img img-responsive new_gallery_img"
                                  alt=""
                                />
                                <img
                                  loading="lazy"
                                  key={Math.random()}
                                  src={`${assetbaseUrl?.value}${els?.media}`}
                                  onClick={() =>
                                    zoomImg(
                                      `${assetbaseUrl?.value}${els?.media}`,
                                    )
                                  }
                                  className="gallery-comp-sub-img img-responsive new_gallery_img_sub"
                                  alt=""
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </div>
              ) : (
                <div>
                  {!loading ? (
                    <>
                      {(window.location.href = "/")}
                      {/* <p className="message-font-size">
                                                {pagemessage}
                                            </p>
                                            <div className='text-center'>
                                                <a href='/' className="btn-close redirect-home"><i class="fas fa-arrow-left mr-2"></i> Back to Homepage</a>
                                            </div> */}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {/* {
                                (window.innerWidth > 991) ?
                                    <InjectScript script={afterPrimary} />
                                    : ''
                            } */}
              {/* {threepost?.[0]?.script &&  */}
              {/* <div className="ad-section"> */}
              {/* <HelperScript argSmall={195} argLarge={51} /> */}
              {/* <InjectScript script={threepost?.[0]?.script} /> */}
              {/* </div> */}
              {/* } */}

              {/* <div id="category_outstream" className='category_outstream' />
                            Category Adsolut tag
                            */}

              <InjectScript script={catevidcrunch} />

              <div className="p-3">
                {window.innerWidth > 991 ? (
                  <InjectScript script={UniBotsplayer} key={Math.random()} />
                ) : (
                  ""
                )}
              </div>
              {innerData?.length > 7 ? (
                <div className="shadow p-3 mb-3 bg-white rounded maincategoryad2">
                  <div className="row substory">
                    {innerData?.length > 0 &&
                      innerData?.map((els, idx) => (
                        <>
                          {idx > 6 && idx < 13 && (
                            <div className="col-lg-6 col-xl-4">
                              <div className="img-zoomer">
                                <img
                                  loading="lazy"
                                  key={Math.random()}
                                  src={`${assetbaseUrl?.value}${els?.media}`}
                                  onClick={() =>
                                    zoomImg(
                                      `${assetbaseUrl?.value}${els?.media}`,
                                    )
                                  }
                                  className="gallery-comp-sub-img img-responsive new_gallery_img"
                                  alt=""
                                />
                                <img
                                  loading="lazy"
                                  key={Math.random()}
                                  src={`${assetbaseUrl?.value}${els?.media}`}
                                  onClick={() =>
                                    zoomImg(
                                      `${assetbaseUrl?.value}${els?.media}`,
                                    )
                                  }
                                  className="gallery-comp-sub-img img-responsive new_gallery_img_sub"
                                  alt=""
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* {sixpost?.[0]?.script && */}
              <div className="ad-section">
                {/* <HelperScript argSmall={196} argLarge={52} /> */}
                {/* <InjectScript script={sixpost?.[0]?.script} /> */}
                {window.innerWidth > 991 ? (
                  <InjectScript script={afterPrimaryad2} />
                ) : (
                  ""
                )}
              </div>
              {/* } */}
              {innerData?.length > 13 ? (
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="row substory">
                    {innerData?.length > 0 &&
                      innerData?.map((els, idx) => (
                        <>
                          {idx > 12 && idx < 16 && (
                            <div className="col-lg-6 col-xl-4">
                              <div className="img-zoomer">
                                <img
                                  loading="lazy"
                                  key={Math.random()}
                                  src={`${assetbaseUrl?.value}${els?.media}`}
                                  onClick={() =>
                                    zoomImg(
                                      `${assetbaseUrl?.value}${els?.media}`,
                                    )
                                  }
                                  className="gallery-comp-sub-img img-responsive new_gallery_img"
                                  alt=""
                                />
                                <img
                                  loading="lazy"
                                  key={Math.random()}
                                  src={`${assetbaseUrl?.value}${els?.media}`}
                                  onClick={() =>
                                    zoomImg(
                                      `${assetbaseUrl?.value}${els?.media}`,
                                    )
                                  }
                                  className="gallery-comp-sub-img img-responsive new_gallery_img_sub"
                                  alt=""
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* {ninepost?.[0]?.script && */}
              <div className="ad-section">
                {/* <HelperScript argSmall={197} argLarge={53} /> */}
                {/* <InjectScript script={ninepost?.[0]?.script} /> */}
              </div>
              {/* } */}

              {innerData?.length > 17 ? (
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="row substory">
                    {innerData?.length > 0 &&
                      innerData?.map((els, idx) => (
                        <>
                          {idx > 16 ? (
                            <div className="col-lg-6 col-xl-4">
                              <div className="img-zoomer">
                                <img
                                  loading="lazy"
                                  key={Math.random()}
                                  src={`${assetbaseUrl?.value}${els?.media}`}
                                  onClick={() =>
                                    zoomImg(
                                      `${assetbaseUrl?.value}${els?.media}`,
                                    )
                                  }
                                  className="gallery-comp-sub-img img-responsive new_gallery_img"
                                  alt=""
                                />
                                <img
                                  loading="lazy"
                                  key={Math.random()}
                                  src={`${assetbaseUrl?.value}${els?.media}`}
                                  onClick={() =>
                                    zoomImg(
                                      `${assetbaseUrl?.value}${els?.media}`,
                                    )
                                  }
                                  className="gallery-comp-sub-img img-responsive new_gallery_img_sub"
                                  alt=""
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* {twelvepost?.[0]?.script &&  */}
              <div className="ad-section">
                {/* <HelperScript argSmall={198} argLarge={54} /> */}
                {/* <InjectScript script={twelvepost?.[0]?.script} /> */}
              </div>
              {/* } */}

              <div>
                <nav
                  aria-label="Page navigation example"
                  style={{ display: pageCount ? "block" : "none" }}
                >
                  <div className="justify-content-center text-center">
                    <ul
                      className={`pagination myActive${pageArray?.length === activePage ? `last` : activePage} myActiveFirst${pageArray?.length === 1 ? `last` : activePage}`}
                    >
                      <li
                        onClick={() => {
                          setActivePage(1);
                        }}
                        className="firstpage page-item"
                      >
                        <a className="page-link">
                          {"<"}
                          {"<"} First
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          if (activePage > 1) {
                            setActivePage(activePage - 1);
                          }
                        }}
                        className="firstpage page-item"
                      >
                        <a className="page-link">Previous</a>
                      </li>

                      {pageArray?.length > 0 &&
                        pageArray?.map(
                          (els, index) =>
                            activePage - 3 <= index &&
                            activePage + 2 > index && (
                              <li
                                onClick={() => setActivePage(els + 1)}
                                key={Math.random()}
                                className={`page-item ${els + 1 === activePage ? "active" : ""}`}
                              >
                                <a className="page-link">{els + 1}</a>
                              </li>
                            ),
                        )}

                      <li
                        onClick={() => {
                          if (pageArray?.length !== activePage) {
                            setActivePage(activePage + 1);
                          }
                        }}
                        className="lastpage page-item"
                      >
                        <a className="page-link">Next</a>
                      </li>
                      <li
                        onClick={() => setActivePage(pageArray?.length)}
                        className="lastpage page-item"
                      >
                        <a className="page-link">
                          Last {">"}
                          {">"}
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              {window.innerWidth > 991 ? (
                <HomeTaboola key={window.location.href} />
              ) : (
                ""
              )}
            </div>

            <div className="insta-head-responsive col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
              <div className="text-center">
                <div className="ad-side-head ">
                  <div className="ad-side m-0">
                    {/* <p>જાહેરાત</p> */}
                    {/* {firstright?.[0]?.script &&  */}
                    <div className="ad-section">
                      {/* <HelperScript argSmall={201} argLarge={57} /> */}
                      {/* {
                                                window.innerWidth < 991 ?
                                                <InjectScript script={category201} />
                                                :
                                                <InjectScript script={category57} />
                                            } */}
                      {/* <InjectScript script={IBVcategoryWeb} /> */}
                      <InjectScript
                        script={
                          window.innerWidth > 991
                            ? IBVcategoryWeb
                            : IBVcategoryMobile
                        }
                      />

                      {/* {
                                                window.innerWidth < 991 ?
                                                <InjectScript script={gallery201} />
                                                :
                                                <InjectScript script={gallery57} />
                                            } */}
                      {/* <InjectScript script={firstright?.[0]?.script} /> */}
                    </div>
                    {/* } */}

                    {/* <img loading="lazy" key={Math.random()} src="https://i.imgur.com/qSBLC3y.png" alt="" /> */}
                  </div>
                  {/* <div className="ad-side">
                                        <p>જાહેરાત</p>
                                        <img loading="lazy" key={Math.random()} src="https://assets.sandesh.com/im/adside.jpg" alt="" />
                                    </div> */}
                </div>
              </div>
              <div className="ad-section">
                <div id="taboola-categorypage-1x1-thumbs"></div>
                <InjectScript
                  script={window.innerWidth > 991 ? Taboola1x1thumbs : ""}
                />
              </div>

              <div className="d-flex justify-content-center">
                <div className="ad-side-head">
                  {/* {secondright?.[0]?.script &&  */}
                  <div className="ad-section">
                    {/* <HelperScript argSmall={202} argLarge={58} /> */}

                    {window.innerWidth < 991 ? (
                      <InjectScript script={category202} />
                    ) : (
                      <InjectScript script={category58} />
                    )}
                    {/* <InjectScript script={secondright?.[0]?.script} /> */}
                  </div>
                  {/* } */}
                </div>
              </div>

              <div className="text-center">
                <div className="ad-side-head ">
                  <div className="ad-side m-0">
                    {/* <p>જાહેરાત</p> */}
                    {/* {thirdright?.[0]?.script &&  */}
                    <div className="ad-section">
                      {/* <HelperScript argSmall={203} argLarge={59} /> */}
                      {/* <InjectScript script={thirdright?.[0]?.script} /> */}
                    </div>
                    {/* } */}

                    {/* <img loading="lazy" key={Math.random()} src="https://i.imgur.com/uakoBY9.png" alt="" /> */}
                  </div>
                  <div className="ad-side">
                    {/* <p>જાહેરાત</p> */}
                    {/* <img loading="lazy" key={Math.random()} src={youmaylike} alt="" /> */}
                    {/* {fourthright?.[0]?.script && */}
                    <div className="ad-section">
                      {/* <HelperScript argSmall={205} argLarge={100} /> */}
                      {/* <InjectScript script={fourthright?.[0]?.script} /> */}
                    </div>
                    {/* } */}

                    {/* <a className="twitter-timeline" href="https://twitter.com/sandeshnews?ref_src=twsrc%5Etfw">Tweets by sandeshnews</a>
                                        <Helmet>
                                            <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                                        </Helmet> */}
                  </div>
                </div>
              </div>

              <div className="my-2 sidebar-taboola" key={window.location.href}>
                {/* Sidbar Taboola with script */}
                <div id="taboola-right-rail-thumbnails"></div>
                <InjectScript
                  type="taboola"
                  script={taboolacontent}
                  key={window.location.href}
                />
                {/* Categoty Sidbar Taboola with NPM
                                <TaboolaComponent
                                    mode="thumbnails-rr"
                                    containerId="taboola-right-rail-thumbnails"
                                    placement="Right Rail Thumbnails'"
                                    targetType="mix"
                                    pageType="category"
                                    publisherId="sandeshdigital-sandesh"
                                /> */}
                <div className="ad-sticky-top">
                  {/* <HelperScript argLarge={261} /> */}
                </div>
              </div>
            </div>
          </div>

          {window.innerWidth < 991 ? (
            <HomeTaboola key={window.location.href} />
          ) : (
            ""
          )}

          {/* <AdTwo /> */}

          {/* <div className='mt-4 mb-4'>
                        <AdTwo />
                    </div> */}
        </div>
      </section>
      {/* {CategoryFooter?.[0]?.script && */}
      <div className="ad-section">
        {/* <HelperScript argSmall={194} argLarge={47} /> */}
        {/* <InjectScript script={CategoryFooter?.[0]?.script} /> */}
      </div>
      {/* } */}
      <div id="videoPoPup" className="videoSidebar">
        <div className="closebtn" onClick={() => VideoModalClose()}>
          <i className="fas fa-times"></i>
        </div>
        <Dailymotion
          className="dailymotion-videoPoPup-page"
          video={`${vidId}`}
          showEndScreen={true}
          uiTheme="light"
          autoplay
          uiShowLogo="false"
          origin=""
        />
        {/* <YouTube className="dailymotion-videoPoPup-page" videoId="CVxjMPK8sFU" opts={optsModal} /> */}

        {/* <div className="videoPoPup-details">
                        <p>{vidTitle}</p>
                        <span><i className="far fa-clock"></i>&nbsp;{vidTime}</span>
                    </div> */}
      </div>

      <div
        className="photo-view"
        style={{
          opacity: zoomimg ? 1 : 0,
          visibility: zoomimg ? "visible" : "hidden",
        }}
        onClickCapture={() => zoomImg(false)}
        onClick={() => zoomImg(false)}
      >
        <i className="fas fa-times" onClick={() => zoomImg(false)} />
        {zoomimg && (
          <img
            loading="lazy"
            src={zoomimg}
            alt=""
            onClick={() => zoomImg(false)}
          />
        )}
      </div>
    </>
  );
};

export default GaneshChaturthiPage;
