import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import sandeshske from "../../assets/sandeshske.png";
import { gameZopFetchData } from "../../redux/actions/commonAction";
import CommonContent from "../CommonContent";
import CommonHeadCardContent from "../CommonHeadCardContent";
import InjectScript from "../CustomScript";

/**
 * World component
 * 
 * This component renders a section for world news on the homepage.
 */
const World = () => {
  const dispatch = useDispatch();
  const { 21: assetbaseUrl } = useSelector(
    (state) => state?.SettingReducer?.data,
  );
  const Worldlist = useSelector(
    (state) => state?.HomeSecondaryReducer?.data?.World,
  );
  const [, setgameZopData] = useState();

  /**
   * Fetch gameZop data
   */
  useEffect(() => {
    dispatch(gameZopFetchData()).then((res) => {
      setgameZopData(res);
    });
  }, []);

  /**
   * Ad script for above world section on mobile
   */
  const aboveWorldMweb =
    "" +
    "<div id='div-gpt-ad-1657863978555-0' style='min-width: 300px; min-height: 100px;'>" +
    "<script>" +
    " window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/Mweb_HP_Aboveworld_300', [300, 100], 'div-gpt-ad-1657863978555-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "" +
    "<script>" +
    "googletag.cmd.push(function() {googletag.display('div-gpt-ad-1657863978555-0'); });" +
    "</script>" +
    "</div>" +
    "";


  /**
   * Taboola ad script for right world section
   */
  const taboolaRightWorld =
    "" +
    "" +
    '<div id="taboola-side-homepage-thumbnails-1x1"></div>' +
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    "_taboola.push({" +
    "mode: 'thumbnails-home-1x1'," +
    "container: 'taboola-side-homepage-thumbnails-1x1'," +
    "placement: 'Side Homepage Thumbnails 1x1 b'," +
    "target_type: 'mix'" +
    "});" +
    "</script>" +
    "";

  /**
   * Ad script for below world section
   */
  const weblastworldsection =
    "" +
    "<div id='div-gpt-ad-1683976634522-0' style='min-width: 728px; min-height: 90px;'>" +
    "<script>" +
    " window.googletag = window.googletag || {cmd: []}; " +
    "googletag.cmd.push(function() {  " +
    "googletag.defineSlot('/22678428348/SDPL_HP_BelowWorld-728x90', [728, 90], 'div-gpt-ad-1683976634522-0').addService(googletag.pubads());  " +
    "googletag.pubads().enableSingleRequest();  " +
    "googletag.pubads().disableInitialLoad(); " +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "" +
    "<script>" +
    " googletag.cmd.push(function() { googletag.display('div-gpt-ad-1683976634522-0'); }); " +
    "</script>" +
    "</div>" +
    "";

  return (
    <>
      {window.innerWidth < 991? (
        <div className="">
          <div className="text-center">
            <InjectScript script={aboveWorldMweb} key={aboveWorldMweb} />
          </div>
        </div>
      ) : (
        ""
      )}
      <section className="world ">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9">
              <div className="single-blog">
                <span className="badge badge-warning">World</span>
                <Link
                  target="_parent"
                  to="/world"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Homepage",
                      action: "World",
                      label: "Read More",
                    })
                  }
                  className="readmore"
                >
                  Read More
                </Link>
              </div>
              <div className="row">
                <div className="col-md-7 col-lg-7 col-xl-7 removePaddingLeft removePaddingHover">
                  {Worldlist?.length > 0 && (
                    <>
                      <CommonHeadCardContent
                        dataClass="margin-bottom margin-bottom-guj"
                        url={Worldlist[0]?.url}
                        sandeshske={sandeshske}
                        media={Worldlist[0]?.media}
                        mediaimg={`${assetbaseUrl?.value}${Worldlist[0]?.media.replace("?resize=800,450", "")}?resize=568,319`}
                        post_type={Worldlist[0]?.post_type}
                        time={Worldlist[0]?.time}
                        title={Worldlist[0]?.title}
                        id={Worldlist[0]?.id}
                        publish_date={Worldlist[0]?.publish_date}
                      />
                      <CommonContent
                        url={Worldlist[1]?.url}
                        src={`${assetbaseUrl?.value}${Worldlist[1]?.media.replace("?resize=800,450", "")}?resize=90,51`}
                        post_type={Worldlist[1]?.post_type}
                        time={Worldlist[1]?.time}
                        publish_date={Worldlist[1]?.publish_date}
                        category={Worldlist[1]?.tagline}
                        title={Worldlist[1]?.title}
                      />
                      <CommonContent
                        url={Worldlist[2]?.url}
                        src={`${assetbaseUrl?.value}${Worldlist[2]?.media.replace("?resize=800,450", "")}?resize=90,51`}
                        post_type={Worldlist[2]?.post_type}
                        time={Worldlist[2]?.time}
                        publish_date={Worldlist[2]?.publish_date}
                        category={Worldlist[2]?.tagline}
                        title={Worldlist[2]?.title}
                      />
                      <CommonContent
                        url={Worldlist[3]?.url}
                        src={`${assetbaseUrl?.value}${Worldlist[3]?.media.replace("?resize=800,450", "")}?resize=90,51`}
                        post_type={Worldlist[3]?.post_type}
                        time={Worldlist[3]?.time}
                        publish_date={Worldlist[3]?.publish_date}
                        category={Worldlist[3]?.tagline}
                        title={Worldlist[3]?.title}
                      />

                   
                    </>
                  )}
                </div>
                <div className="col-md-5 col-lg-5 col-xl-5 removePaddingRight">
             
                  {Worldlist?.length > 0 &&
                    Worldlist?.map((els, idx) => (
                      <>
                        {idx > 3 && (
                          <CommonContent
                            url={els?.url}
                            src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=90,51`}
                            post_type={els?.post_type}
                            time={els?.time}
                            publish_date={els?.publish_date}
                            category={els?.tagline}
                            title={els?.title}
                          />
                        )}
                      </>
                    ))}
                </div>
              </div>
              {window.innerWidth > 991? (
                <div className="">
                  <div className="text-center">
                    <InjectScript
                      script={weblastworldsection}
                      key={weblastworldsection}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 ad-side offset-md-3 col-lg-3 offset-lg-0 col-xl-3 offset-xl-0 text-center">
              <div className="ad-section">
                <InjectScript
                  key={window.location.pathname}
                  type="taboola"
                  script={taboolaRightWorld}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default World;