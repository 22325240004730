/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import epaper from "../../../assets/sandeshEPaper.png";
import { useSelector, useDispatch } from "react-redux";
import HomeTaboola from "../../subcomponents/HomeTaboola";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { ePaperImageFetchData } from "../../../redux/actions/EPaperImageAction";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import InjectScript from "../../CustomScript";

const Epaper = () => {
  const ePaperMenuData = useSelector((state) => state?.ePaperMenuReducer?.data?.data);
  const ePaperImageData = useSelector((state) => state?.ePaperImageReducer?.data);
  const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);

  const dispatch = useDispatch();
  const [myDate, setMyDate] = useState(new Date().toISOString().substring(0, 10));

  useEffect(() => {
    dispatch(ePaperImageFetchData(myDate));
    if (document.querySelector(".brand")) {
      document.querySelector(".brand").focus();
    }
  }, [dispatch, myDate]);

  function createAd(slotIdentifier, adSize, divId, disableInitialLoad = false) {
    const [width, height] = adSize;

    const adHtml = `<div id="${divId}" style="min-width: ${width}px; min-height: ${height}px;">
    <script>
      window.googletag = window.googletag || { cmd: [] };
      googletag.cmd.push(function() {
        googletag.defineSlot(${slotIdentifier}, ${adSize}, ${divId}).addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        ${disableInitialLoad ? `googletag.pubads().disableInitialLoad();` : ''}
        googletag.enableServices();
      });
    </script>
    <script>
      googletag.cmd.push(function() { googletag.display(${divId}); });
    </script>
  </div>
  `;

    return adHtml;
  }

  const RenderEPaperFrontPage = ({ el, els }) => {
    return <Link
      target="_parent"
      to={`${el?.category}?date=${myDate}`}
      onClickCapture={() =>
        ReactGA.event({
          category: "E-Paper",
          action: els?.name,
          label: el?.name,
        })
      }
    >
      <div className="img-in-txt margin-bottom-guj">
        <img
          loading="lazy"
          className="epaper-news-img"
          src={
            ePaperImageData[
              el?.category
            ]
              ? assetbaseUrl?.value +
              ePaperImageData[
              el?.category
              ]
              : epaper
          }
          alt=""
        />
        <div className=" content-epaper blog-details content-epaper1 ">
          <p className="epaper-bottom-text row px-3">
            {el?.name}
            <a>
              <i className=" far fa-angle-double-right"></i>
            </a>
          </p>
        </div>
      </div>
    </Link>
  }

  const afterCity = createAd('/22678428348/Mweb_Epaper_Aftereverycity_300X250', [300, 250], 'div-gpt-ad-1657960450250-0');
  const supplementAfter3 = createAd('/22678428348/Mweb_Epaper_Insupplement_300x250', [300, 250], 'div-gpt-ad-1657962041463-0');
  const ahmedabadAfter2 = createAd('/22678428348/Mweb_Epaper_InAhmedabad_300X250', [300, 250], 'div-gpt-ad-1657962640252-0');

  const ePaper103 = createAd('/22678428348/SDPL_Epaper_Left_160X600', [160, 600], 'div-gpt-ad-1646038593543-0', 1);
  const ePaper104 = createAd('/22678428348/SDPL_Epaper_Right_160X600', [160, 600], 'div-gpt-ad-1646038617823-0', 1);

  const ePaper207 = "";
  const ePaper208 = createAd('/22678428348/Mweb_Epaper_Right_300x250', [300, 250], 'div-gpt-ad-1646388837706-0', 1);

  const rajkotCityAds = createAd('/22678428348/Mweb_Epaper_AfterSurat_300x100', [300, 100], 'div-gpt-ad-1677757000770-0', 1);
  const vadodaraCityAds = createAd('/22678428348/Mweb_Epaper_AfterRajkot_300x100', [300, 100], 'div-gpt-ad-1677757288216-0', 1);
  const bhujCityAds = createAd('/22678428348/Mweb_Epaper_AfterVadodara_300x100', [300, 100], 'div-gpt-ad-1677758558946-0', 1);
  const bhavnagarCityAds = createAd('/22678428348/Mweb_Epaper_AfterBhuj_300x100', [300, 100], 'div-gpt-ad-1677759217763-0', 1);
  const suratUnibotsAds = "";

  return (
    <>
      <Helmet>
        <title>
          Gujarat E-Paper | Gujarati E-Paper | E-Paper news in Gujarati | Sandesh
        </title>
      </Helmet>
      <div className="container">
        <div className="row e-paper e-paper_new">
          <div className="col-md-2">
            <div className="add-fix-top">
              <div className="ad-side">
                <div className="ad-section">
                  {window.innerWidth < 991 ? (
                    <InjectScript
                      key={window.location.pathname}
                      script={ePaper207}
                    />
                  ) : (
                    <InjectScript
                      key={window.location.pathname}
                      script={ePaper103}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 new_data">
            <input
              type="date"
              className="is-date_new form-control datepicker date_input"
              value={myDate}
              max={new Date().toISOString().substring(0, 10)}
              onChange={(e) => setMyDate(e.target.value)}
            />
            {ePaperMenuData &&
              Object.values(ePaperMenuData)?.map((els) => (
                <>
                  {els?.open !== 1 && (
                    <div className="epaper-category e-paper_new">
                      {window.innerWidth < 991 &&
                        els?.name == "Rajkot City" && (
                          <InjectScript script={rajkotCityAds} />
                        )}
                      {window.innerWidth < 991 && els?.name == "Vadodara" && (
                        <InjectScript script={vadodaraCityAds} />
                      )}
                      {window.innerWidth < 991 && els?.name == "Bhuj" && (
                        <InjectScript script={bhujCityAds} />
                      )}
                      {window.innerWidth < 991 && els?.name == "Bhavnagar" && (
                        <InjectScript script={bhavnagarCityAds} />
                      )}
                      {window.innerWidth > 991 && els?.name == "Surat City" && (
                        <InjectScript
                          script={suratUnibotsAds}
                          key={Math.random()}
                        />
                      )}

                      <h5 className="my-3 text-uppercase city_name">
                        {els?.name}
                      </h5>
                      {els?.name === "Supplements" ? (
                        <>
                          <div className=" row city_paper">
                            <>
                              {els?.submenu &&
                                Object.values(els?.submenu).map((el, idx) => (
                                  <>
                                    {el?.open !== 1 && (
                                      <>
                                        {idx >= 0 && idx < 6 ? (
                                          <div className="col-md-4 mb-3">
                                            <RenderEPaperFrontPage el={el} els={els} />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </>
                                ))}
                            </>
                            {window.innerWidth < 767 ? (
                              <div className="text-center">
                                <InjectScript
                                  script={supplementAfter3}
                                  key={supplementAfter3}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            <>
                              {els?.submenu &&
                                Object.values(els?.submenu).map((el, idx) => (
                                  <>
                                    {el?.open !== 1 && (
                                      <>
                                        {idx >= 6 && idx < 12 ? (
                                          <div className="col-md-4 mb-3">
                                            <RenderEPaperFrontPage el={el} els={els} />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </>
                                ))}
                            </>
                            {window.innerWidth < 767 ? (
                              <div className="text-center">
                                <InjectScript
                                  script={supplementAfter3}
                                  key={supplementAfter3}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {els?.name === "Ahmedabad" ? (
                        <>
                          <div className="row city_paper">
                            <>
                              {els?.submenu &&
                                Object.values(els?.submenu).map((el, idx) => (
                                  <>
                                    {el?.open !== 1 && (
                                      <>
                                        {idx >= 0 && idx < 4 ? (
                                          <div className="col-md-4 mb-3">
                                            <RenderEPaperFrontPage el={el} els={els} />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </>
                                ))}
                            </>
                            {window.innerWidth < 767 ? (
                              <div className="text-center">
                                <InjectScript
                                  script={ahmedabadAfter2}
                                  key={ahmedabadAfter2}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            <>
                              {els?.submenu &&
                                Object.values(els?.submenu).map((el, idx) => (
                                  <>
                                    {el?.open !== 1 && (
                                      <>
                                        {idx >= 4 && idx < 8 ? (
                                          <div className="col-md-4 mb-3">
                                            <RenderEPaperFrontPage el={el} els={els} />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </>
                                ))}
                            </>
                            <>
                              {els?.submenu &&
                                Object.values(els?.submenu).map((el, idx) => (
                                  <>
                                    {el?.open !== 1 && (
                                      <>
                                        {idx >= 8 && idx < 12 ? (
                                          <div className="col-md-4 mb-3">
                                            <RenderEPaperFrontPage el={el} els={els} />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </>
                                ))}
                            </>
                          </div>
                          <div id="epaper_clipped" class="epaper_clipped"></div>
                        </>
                      ) : (
                        ""
                      )}
                      {els?.name !== "Supplements" &&
                        els?.name !== "Ahmedabad" ? (
                        <div className="row city_paper">
                          {els?.submenu &&
                            Object.values(els?.submenu).map((el, idx) => (
                              <>
                                {el?.open !== 1 && (
                                  <div className="col-md-4 mb-3">
                                    <Link
                                      target="_parent"
                                      to={`${el?.category}?date=${myDate}`}
                                      onClickCapture={() =>
                                        ReactGA.event({
                                          category: "E-Paper",
                                          action: els?.name,
                                          label: el?.name,
                                        })
                                      }
                                    >
                                      <div className="img-in-txt margin-bottom-guj">
                                        <img
                                          loading="lazy"
                                          className="epaper-news-img"
                                          src={
                                            ePaperImageData[el?.category]
                                              ? assetbaseUrl?.value +
                                              ePaperImageData[el?.category]
                                              : epaper
                                          }
                                          alt=""
                                        />
                                        <div className=" content-epaper blog-details content-epaper1 ">
                                          <p className="epaper-bottom-text row px-3">
                                            {el?.name}
                                            <a>
                                              <i className=" far fa-angle-double-right"></i>
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                )}
                              </>
                            ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  {window.innerWidth < 767 && els?.name == "ahmedabad" ? (
                    <div className="text-center">
                      <InjectScript script={afterCity} key={afterCity} />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ))}{" "}
            <HomeTaboola key={window.location.href} />
          </div>
          <div className="col-md-2">
            <div className="add-fix-top">
              <div className="ad-side">
                <div className="ad-section">
                  {window.innerWidth < 991 ? (
                    <InjectScript
                      key={window.location.pathname}
                      script={ePaper208}
                    />
                  ) : (
                    <InjectScript
                      key={window.location.pathname}
                      script={ePaper104}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Epaper;
